import { useInfiniteQuery } from '@tanstack/react-query';
import { useRef } from 'preact/hooks';

import { setQueryParams, useQueryParams } from '@/hooks/route';
import { getInfiniteVideosQueryOptions } from '@/queries/media';
import { toVideoType, Video, VideoType } from '@/queries/media/types';

export const useHighlightType = (queryKey: string = 'type', defaultValue: VideoType = 'clip') => {
  const params = useQueryParams();
  return toVideoType(params.get(queryKey)) ?? defaultValue;
};

const QUERY_KEY_PARAMS = { highlight: '3', sortOrder: 'desc', pageSize: 12 };

export const useHighlightsListData = (queryKey?: string) => {
  const highlightType = useHighlightType(queryKey);

  return useInfiniteQuery(getInfiniteVideosQueryOptions({ ...QUERY_KEY_PARAMS, type: highlightType }));
};

export const useHighlightsList = (queryKey?: string) => {
  const highlightType = useHighlightType(queryKey);
  const listRef = useRef<HTMLUListElement>(null);
  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    getInfiniteVideosQueryOptions({ ...QUERY_KEY_PARAMS, type: highlightType }),
  );

  const fetchMoreIfNeeded = (index: number) => {
    if (!data || isFetchingNextPage || !hasNextPage) return;
    // fetch more highlights if we are near the end
    if (index > data.list.length - 3) {
      fetchNextPage();
    }
  };

  const onHighlightClick = (_: Video, index: number) => {
    fetchMoreIfNeeded(index);
  };

  const onChangeHighlightType = (type: VideoType) => {
    if (type !== highlightType) {
      setQueryParams({ type }, true);
    }
  };

  return {
    listRef,
    highlightType,
    data,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    fetchNextPage,
    fetchMoreIfNeeded,
    onHighlightClick,
    onChangeHighlightType,
  };
};
