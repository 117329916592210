import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Ref } from 'preact/hooks';
import { Permission } from 'zl-shared/dist/auth';

import { Video } from '@/queries/media/types';

import { hasPermission } from '../Authorize';
import { PrimaryButton } from '../Buttons';
import EditVideoModal from '../EditVideoModal';
import { useIsMobileLayout } from '../LayoutDetector/hooks';
import Loader from '../Loader';
import RoundButton from '../RoundButton';

interface HighlightsListProps {
  type: string;
  className?: string;
  highlights?: Video[];
  currentHighlightIndex?: number;
  onHighlightClick: (highlight: Video, index: number) => void;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  onLoadMore?: () => void;
  listRef?: Ref<HTMLUListElement>;
  onUpdate?: () => void;
}

const HighlightsList = ({
  type,
  className,
  highlights,
  currentHighlightIndex,
  onHighlightClick,
  isFetchingNextPage,
  hasNextPage,
  onLoadMore,
  listRef,
  onUpdate,
}: HighlightsListProps) => {
  const isMobile = useIsMobileLayout();
  const canUploadMedia = hasPermission(Permission.Habitat.UploadMedia);

  return (
    <div className={className}>
      <ul ref={listRef} className="preflight preflight-ul grid grid-cols-1 gap-6 desktop:grid-cols-3 desktop:gap-3">
        {highlights?.map((highlight, index) => (
          <li data-group="Highlight List Item" key={highlight._id} data-id={highlight._id} className="mobile:scroll-m-2">
            <a
              data-native
              href={`/highlights?type=${type}&id=${highlight._id}`}
              aria-disabled={highlight.disabled}
              className="preflight preflight-a group relative block gap-4 transition-opacity hover:opacity-80 aria-disabled:opacity-50 mobile:grid mobile:grid-cols-[150px_1fr]"
              onClick={(e) => {
                e.preventDefault();
                onHighlightClick(highlight, index);
              }}
            >
              <div className="preflight relative overflow-hidden rounded desktop:rounded-lg">
                <img
                  className="preflight preflight-img aspect-video"
                  src={highlight.thumbnailURL}
                  alt={highlight.title}
                  loading="lazy"
                />
                {currentHighlightIndex === index && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black/75">
                    <div className="flex h-6 items-end gap-0.5 desktop:h-10">
                      <span className="h-[60%] w-2 animate-soundbar bg-white desktop:w-4" />
                      <span className="h-[30%] w-2 animate-soundbar bg-white [animation-delay:-2.2s] desktop:w-4" />
                      <span className="h-[75%] w-2 animate-soundbar bg-white [animation-delay:-3.7s] desktop:w-4" />
                    </div>
                  </div>
                )}
              </div>
              <div className="min-w-0 break-words desktop:hidden">
                <h5 className="preflight preflight-h5 line-clamp-3 text-sm font-semibold">{highlight.title}</h5>
                <p className="preflight preflight-p mt-1 text-xs font-light">
                  {highlight.type === 'curated' ? 'Added by zoolife' : `Taken by @${highlight.username}`}
                </p>
              </div>
              {canUploadMedia && (
                <div className="pointer-events-none absolute right-2 top-2 z-[3] hidden flex-row-reverse gap-3 desktop:group-hover:flex md:right-4 md:top-4">
                  <EditVideoModal.Trigger
                    videoId={highlight._id}
                    defaultThumbnailURL={highlight.thumbnailURL}
                    defaultTitle={highlight.title}
                    onUpdate={onUpdate}
                    render={(setIsOpen) => (
                      <RoundButton
                        width={24}
                        color="var(--blueDark)"
                        backgroundColor="var(--white)"
                        className="pointer-events-auto"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsOpen(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} className="text-xs" />
                      </RoundButton>
                    )}
                  />
                </div>
              )}
            </a>
          </li>
        ))}
      </ul>
      {isFetchingNextPage && <Loader color="var(--charcoal)" className="my-4" width="64px" height="64px" />}
      {hasNextPage && !isFetchingNextPage && (
        <div className="my-4 flex justify-center">
          <PrimaryButton label="Load More" size={isMobile ? 'medium' : 'large'} onClick={onLoadMore} />
        </div>
      )}
    </div>
  );
};

export default HighlightsList;
