import 'react-colorful/dist/index.css';

import { faEyeDropper, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Box, Button, Grid, Text } from 'grommet';
import { debounce } from 'lodash-es';
import { useCallback, useMemo, useRef, useState } from 'preact/hooks';
import { HexColorPicker } from 'react-colorful';
import { getIconKeys, getIconUrl } from 'Shared/profileIcons';

import { useOnClickOutside } from '../../hooks';
import style from './style.scss';

const icons = getIconKeys();
const colors = [
  '#FFB145',
  '#FF8A00',
  '#F85C14',
  '#CE0C0F',
  '#7D0555',
  '#FFA8EC',
  '#F569A4',
  '#C930A7',
  '#A129FF',
  '#5260DD',
  '#76A6F2',
  '#529ADD',
  '#368185',
  '#76ADAA',
];

const replacementColor = '#477652';

const ColorItem = ({ color, selected, onClick }) => (
  <button
    role="radio"
    aria-checked={selected}
    type="button"
    aria-label="color"
    className={clsx(style.circleItem, { [style.selected]: selected })}
    onClick={() => onClick(color)}
    style={{ color }}
  />
);

const IconItem = ({ icon, color, selected, onClick }) => (
  <button
    role="radio"
    aria-checked={selected}
    type="button"
    aria-label="color"
    className={clsx(style.circleItem, style.animalIcon, { [style.selected]: selected })}
    onClick={() => onClick(icon)}
    style={{ color }}
  >
    <div className={style.icon} style={{ backgroundImage: `url('${icon}')` }} />
  </button>
);

const circlesGridConfig = {
  columns: ['auto', 'auto', 'auto', 'auto', 'auto'],
  gap: { column: '20px', row: '20px' },
};

/**
 *
 * @param {Object} props
 * @param {string} props.icon
 * @param {string} props.color
 * @param {function} props.setColor
 * @param {function} props.setIcon
 * @param {boolean} [props.showColorPicker]
 * @param {string} [props.className]
 * @param {import('preact/compat').ReactNode} [props.colorPickerHeader]
 * @param {import('preact/compat').ReactNode} [props.iconPickerHeader]
 * @returns {import('preact').JSX.Element}
 */
const IconPicker = ({ icon, color, setColor, setIcon, showColorPicker, className, colorPickerHeader, iconPickerHeader }) => {
  const pickerRef = useRef();
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const isCustomColor = useMemo(() => !colors.includes(color), [color]);

  const onPickerBtn = () => {
    setIsPickerOpen(true);
  };

  const onPickerCloseBtn = useCallback((evt) => {
    evt.stopPropagation();
    setIsPickerOpen(false);
  }, []);

  useOnClickOutside(pickerRef, onPickerCloseBtn);
  const onColorChange = useMemo(() => debounce(setColor, 10), [setColor]);

  return (
    <Box className={className}>
      <Box>
        {colorPickerHeader ?? (
          <Text margin={{ bottom: 'small' }} size="xlarge" weight={500}>
            Select my color:
          </Text>
        )}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Grid {...circlesGridConfig}>
          {colors.map((col) => (
            <ColorItem key={col} selected={color === col} color={col} onClick={setColor} />
          ))}

          {showColorPicker && (
            <div className={style.colorPickerWrapper}>
              <button
                role="radio"
                aria-checked={isCustomColor}
                type="button"
                aria-label="close picker"
                className={clsx(style.pickerBtn, { [style.selected]: isCustomColor })}
                // set color only if it's not in the list
                style={{
                  color: !isCustomColor ? undefined : color,
                }}
                onClick={onPickerBtn}
              >
                <FontAwesomeIcon icon={faEyeDropper} color={!isCustomColor ? 'var(--grey)' : '#fff'} />
              </button>
              {isPickerOpen && (
                <div ref={pickerRef} className={style.picker}>
                  <div>
                    <p>Pick a custom color:</p>
                    <Button icon={<FontAwesomeIcon icon={faTimes} />} onClick={onPickerCloseBtn} />
                  </div>
                  <HexColorPicker color={color} onChange={onColorChange} />
                </div>
              )}
            </div>
          )}

          {!showColorPicker && (
            <ColorItem
              key={replacementColor}
              selected={color === replacementColor}
              color={replacementColor}
              onClick={setColor}
            />
          )}
        </Grid>
      </Box>

      <Box>
        {iconPickerHeader ?? (
          <Text margin={{ top: 'large', bottom: 'small' }} size="xlarge" weight={500}>
            Select my avatar:
          </Text>
        )}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Grid {...circlesGridConfig}>
          {icons.map((ico) => (
            <IconItem key={ico} selected={icon === ico} icon={getIconUrl(ico)} onClick={() => setIcon(ico)} color={color} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default IconPicker;
