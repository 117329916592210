import { faXmark, faXmarkLarge } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useIsMobileLayout } from '@/components/LayoutDetector/hooks';
// import Video from '@/components/Video';
import VideoPlayer from '@/components/VideoPlayer';
import { Video } from '@/queries/media/types';
import { trackMP } from '@/shared/mp';

import HomeVideoPlayer from '../HomeVideoPlayer';

interface HomeHighlightVideoPlayerProps {
  highlight: Video;
  onNextHighlight?: () => void;
  onSwitchContentMode?: () => void;
  miniMode?: boolean;
  onClose?: () => void;
  muted?: boolean;
  onMuteChange?: (muted: boolean) => void;
}

const HomeHighlightVideoPlayer = ({
  highlight,
  onNextHighlight,
  miniMode,
  onClose,
  onSwitchContentMode,
  muted,
  onMuteChange,
}: HomeHighlightVideoPlayerProps) => {
  const isMobile = useIsMobileLayout();

  return (
    <HomeVideoPlayer.Container miniMode={miniMode}>
      <HomeVideoPlayer.Player>
        <VideoPlayer
          key={highlight.rawVideoURL}
          className="mx-auto"
          width="100%"
          height="100%"
          videoURL={highlight.rawVideoURL}
          videoId={highlight._id}
          alt={highlight.title}
          autoPlay
          muted={muted}
          controls={!miniMode}
          onEnd={onNextHighlight}
          onLoad={() =>
            trackMP('watch-video', {
              type: 'homeHighlightVideo',
              videoURL: highlight.rawVideoURL,
              title: highlight.title,
              mediaId: highlight._id,
            })
          }
          onMuteChange={onMuteChange}
          analyticsData={{
            habitatName: highlight.habitatId?.title,
            habitatId: highlight.habitatId?._id,
            slug: highlight.habitatId ? `${highlight.habitatId.zoo.slug}/${highlight.habitatId.slug}` : undefined,
            title: highlight.title,
            player: 'home-highlight-video',
            videoType: highlight.type,
          }}
        />
        {!isMobile && miniMode && (
          <>
            <HomeVideoPlayer.ExpandButton onClick={onSwitchContentMode} />
            {/* Next button on desktop mini player */}
            {onNextHighlight && (
              <div className="absolute bottom-2 right-2 transition-opacity desktop:opacity-0 desktop:group-hover/home-player:opacity-100">
                <button
                  type="button"
                  className="preflight preflight-button z-10 rounded-full bg-white px-3 py-1 text-xs leading-none hover:bg-grey-5"
                  onClick={() => {
                    onNextHighlight();
                    trackMP('home-next-highlight-clicked', {
                      videoURL: highlight.rawVideoURL,
                      title: highlight.title,
                      mediaId: highlight._id,
                    });
                  }}
                >
                  <span>Next</span>
                </button>
              </div>
            )}
            {/* Close button on desktop mini player */}
            <button
              type="button"
              className="preflight preflight-button absolute right-0 top-0 flex items-center justify-center p-2 text-base text-white opacity-0 drop-shadow-lg transition-opacity hover:text-grey-5 group-hover/home-player:opacity-100"
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faXmarkLarge} />
              <span className="sr-only">Close mini player</span>
            </button>
          </>
        )}
      </HomeVideoPlayer.Player>
      {miniMode && isMobile && (
        <div className="flex min-w-0 grow items-center p-2 pr-4">
          <div className="flex min-w-0 grow items-center px-2">
            <div className="mr-4 flex flex-col justify-center text-white">
              <h4 className="preflight preflight-h4 text-sm font-bold">{highlight.habitatId?.title ?? highlight.title}</h4>
              <span className="text-xs">{highlight.habitatId?.zoo.name}</span>
            </div>
            <HomeVideoPlayer.ExpandButton onClick={onSwitchContentMode} />
          </div>
          <button type="button" className="preflight preflight-button" onClick={onClose}>
            <span className="flex size-6 items-center justify-center rounded-full bg-white text-primary-green group-hover:bg-grey-5">
              <FontAwesomeIcon icon={faXmark} className="h-4 w-4" />
            </span>
            <span className="sr-only">Close mini player</span>
          </button>
        </div>
      )}
    </HomeVideoPlayer.Container>
  );
};

export default HomeHighlightVideoPlayer;
